.Heading {
  color: var(--mb-color-text-dark);
  font-size: 1.125rem;
}

.SidebarHeader {
  border-bottom: 1px solid var(--mb-color-border);
}

.SidebarBody {
  flex: 1;
  overflow-y: auto;
}

.SidebarFooter {
  border-top: 1px solid var(--mb-color-border);
}
