.BehaviorOptionIcon {
  border-color: var(--mb-color-border);

  .Icon {
    color: var(--mb-color-text-white);
  }

  &.isSelected {
    border-color: transparent;

    .Icon {
      color: var(--mb-color-text-white);
    }
  }
}
