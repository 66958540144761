.wrapper {
  background-color: var(--mb-color-bg-light);
  border-left: 1px solid var(--mb-color-border);
  height: 100%;
}

.results {
  overflow: auto;
  height: 100%;

  /* specificity */
  .name {
    line-height: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.description {
  margin: 0;
  padding: 0;
}

.clause {
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: var(--mb-color-focus);
  }
}

.structure {
  background-color: var(--mb-color-bg-white);
  border-bottom: 1px solid var(--mb-color-border);
  font-size: 0.75rem;
  color: var(--mb-color-text-secondary);
  font-family: var(--mb-default-monospace-font-family);
}

.documentation {
  height: 100%;
  overflow: auto;
  flex-shrink: 1;
}
