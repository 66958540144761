.ItemTitle {
  color: var(--mb-color-text-secondary);
  min-width: 10ch;
}

.ItemIcon {
  margin: 0 0.5em;
  margin-left: 0.75em;
  color: var(--mb-color-text-light);
}

.ItemList {
  padding: 0.5em;
}

.Label {
  display: flex;
  align-items: center;
  padding: 0 0.5em;
  padding-right: 0;
  border-radius: 6px;
  cursor: pointer;
  min-height: 2.25rem;

  &:hover {
    background: var(--mb-color-bg-medium);
  }
}

.ToggleItem {
  border-bottom: 1px solid var(--mb-color-border);
  margin-bottom: 0.5em;

  .ItemTitle {
    margin-left: 1em;
  }
}
