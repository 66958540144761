.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pivotedFirstColumn {
  border-right: 1px solid var(--mb-color-border);
}

.bodyCellWithImage {
  padding-top: 0;
  padding-bottom: 0;
}

.bodyCellWithImage img {
  vertical-align: middle;
}
