.Skeleton {
  background-color: var(--mb-color-background-disabled);

  &::before {
    background: linear-gradient(
      100deg,
      transparent,
      var(--mb-color-bg-medium),
      transparent
    );
    animation: shimmer 1.4s linear infinite;
  }

  &::after {
    display: none;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
