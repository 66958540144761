.ItemName {
  color: var(--mb-color-brand);
}

.ColumnClickBehaviorHeader {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: var(--mb-color-brand);
  }
}

.ChevronIconContainer {
  padding: 4px 6px;
  margin-right: 8px;
  border: 1px solid var(--mb-color-border);
  border-radius: 4px;
}
