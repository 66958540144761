.StyledAnchor {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  &:hover {
    color: var(--mb-color-text-dark);
  }
}
