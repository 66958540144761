.root {
  &[data-radix-scroll-area-viewport] {
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
