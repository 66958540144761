.usage {
  padding: 0.25rem 0;
  padding-left: 0.5rem;
  background-color: white;
  color: var(--mb-color-text-secondary);
  cursor: pointer;
  font-family: var(--mb-default-monospace-font-family);
  flex-shrink: 0;
  font-size: 0.75rem;
  min-height: 2.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.toggle {
  margin-left: auto;
  padding: 0 0.65rem;
  display: flex;
  align-items: center;
}

.chevron {
  color: var(--mb-color-text-secondary);

  &.open {
    transform: rotate(90deg);
  }
}

.arg {
  color: var(--mb-color-syntax-parameters);
  font-family: var(--mb-default-monospace-font-family);
  font-size: 0.75rem;
  line-height: 1.57em;
  border-radius: 2px;

  &.active {
    background-color: var(--mb-color-syntax-parameters-active);
  }
}

.info {
  color: var(--mb-color-text-primary);
  font-family: var(--mb-default-font-family);
  padding: 0.5rem;
  overflow: auto;
  border-top: 1px solid var(--mb-color-border);
  background-color: var(--mb-color-bg-light);
  height: calc(50% - 2.5rem);

  &:last-child {
    height: calc(100% - 2.5rem);
  }
}

.title {
  color: var(--mb-color-text-secondary);
  margin: 0.5rem 0;
}

.arguments {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(4rem, max-content));
  margin: 1rem 0;

  .arg {
    line-height: 1.92;
  }
}

.documentationLink {
  display: inline-flex;
  align-items: center;
  margin-top: 1rem;
  color: var(--mb-color-brand);
  font-weight: 700;
}
