/* set main elements to box-sizing border-box for more reliable box model calc */
body:where(:global(.mb-wrapper)) {
  box-sizing: border-box;
}

:where(:global(.mb-wrapper)) {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}
