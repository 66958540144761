.Root {
  flex-basis: 20%;
  flex-grow: 0;

  .FooterButtonGroup {
    display: inline-flex;
    align-items: stretch;

    .Button {
      border: 1px solid var(--mb-color-bg-white);
    }
  }
}
