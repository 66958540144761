.Header {
  color: var(--mb-color-text-medium);
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--mb-color-border);
  display: flex;
  align-items: center;
  height: 3rem;

  .HeaderButton {
    color: var(--mb-color-text-light);

    &:hover {
      color: var(--mb-color-brand);
    }
  }
}
