.NavLink {
  border-radius: 0.5rem;

  &[data-variant="default"] {
    &:hover {
      background-color: var(--mb-color-brand-lighter);
    }

    &[data-active] {
      color: var(--mb-color-text-white);

      &,
      &:hover {
        background-color: var(--mb-color-brand);
      }

      .NavLinkLabel {
        color: var(--mb-color-text-white);
      }

      .NavLinkChevron {
        color: var(--mb-color-text-white);
      }
    }

    &:not([data-active]) {
      /* apply default icon color for section icons when inactive */
      .NavLinkSection {
        color: var(--mb-color-text-primary);
      }
    }
  }

  &[data-variant="mb-light"] {
    &:hover {
      background-color: var(--mb-color-brand-lighter);
    }

    &[data-active] {
      &,
      .NavLinkLabel {
        color: var(--mb-color-text-primary);
      }

      background-color: var(--mb-color-bg-medium);
    }
  }
}

.NavLinkLabel {
  font-size: 14px;
}

.NavLinkChevron {
  color: var(--mb-color-text-dark);
}

.NavLinkSection {
  &[data-position="left"] {
    margin-right: var(--mantine-spacing-sm);
  }

  &[data-position="right"] {
    margin-left: var(--mantine-spacing-sm);
  }
}
