.TabsList {
  padding: 0 2.5rem;
  background-color: var(--mb-color-bg-light);
  border-bottom-width: 1px;
  margin-top: 1.5rem;
  border-bottom-color: var(--mb-color-border);
}

.Tab {
  top: 1px;
  margin-bottom: 1px;
  border-bottom-width: 3px !important;
  padding: 0.625rem 0;
  margin-inline-end: 1.25rem;

  :hover {
    color: var(--mb-color-brand);
    background-color: inherit;
    border-color: transparent;
  }
}

.TabsPanel {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  justify-content: stretch;
}

.hideOverflow {
  overflow: hidden;
}

.TabBody {
  flex: 1;
  background-color: var(--mb-color-bg-light);
  height: 100%;
}

.DatabasesTabBody {
  padding: 1rem 2.5rem;
  overflow: hidden;
}

.ModelPersistenceConfigurationTabBody {
  padding: 1rem 2.5rem;
}

.FormBox {
  overflow: auto;
  flex-grow: 1;
  padding-bottom: 2.5rem;
  padding-inline: 2.5rem;

  &.FormBoxSidebar {
    padding-inline: 1rem;
  }
}
