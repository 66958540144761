.EditWarning {
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.HeaderBadges {
  align-items: center;
  border-left: 1px solid transparent;

  @media screen and (max-width: 40em) {
    flex-direction: column;
    align-items: baseline;
  }
}

.HeaderContainer {
  &.isFixedWidth.offsetSidebar {
    margin-right: var(--sidebar-width);
  }

  @media screen and (max-width: 60em) {
    margin-right: 0;
  }
}

.HeaderButtonsContainer {
  align-items: center;
  color: var(--mb-color-text-dark);

  @media screen and (min-width: 40em) {
    margin-left: auto;
  }

  @media screen and (max-width: 60em) {
    &.isNavBarOpen {
      width: 100%;
      margin-bottom: 6px;
    }
  }

  @media screen and (max-width: 40em) {
    width: 100%;
    margin-bottom: 6px;
    padding-top: 0.375rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-top: 1px solid var(--mb-color-border);
  }
}

.HeaderButtonSection {
  align-items: center;
  gap: 0.5rem;

  @media screen and (max-width: 60em) {
    &.isNavBarOpen {
      width: 100%;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 40em) {
    width: 100%;
    justify-content: space-between;
  }
}

.HeaderLastEditInfoLabel {
  transition: opacity 400ms ease;

  @media screen and (max-width: 40em) {
    margin-top: 4px;
  }
}

.HeaderCaptionContainer {
  position: relative;
  transition: top 400ms ease;
  padding-right: 2rem;
  right: 0.25rem;
  align-items: center;
}

.HeaderContent {
  padding-top: 1rem;
  padding-bottom: 0.75rem;

  .HeaderCaptionContainer {
    top: 10px;
  }

  .HeaderLastEditInfoLabel {
    opacity: 0;
  }

  &:hover,
  &:focus-within,
  &.showSubHeader {
    .HeaderCaptionContainer {
      top: 0;
    }

    .HeaderLastEditInfoLabel {
      opacity: 1;
    }
  }

  @media screen and (max-width: 40em) {
    padding-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;

    .HeaderCaptionContainer {
      top: 0;
    }

    .HeaderLastEditInfoLabel {
      opacity: 1;
    }
  }
}

.HeaderCaption {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.HeaderRow {
  @media screen and (max-width: 40em) {
    padding-left: 0;
    padding-right: 0;
  }
}

.HeaderFixedWidthContainer {
  display: flex;
  align-items: center;
  justify-items: apart;

  @media screen and (max-width: 60em) {
    &.isNavBarOpen {
      flex-direction: column;
      align-items: baseline;
    }
  }

  @media screen and (max-width: 40em) {
    flex-direction: column;
    align-items: baseline;
  }
}
