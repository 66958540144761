.SelectedEntityPickerIcon {
  border-color: transparent;
}

.SelectedEntityPickerContent {
  font-weight: bold;
}

.ButtonLabel {
  /* we need to show chevron on the right */
  flex-grow: 1;
  text-align: left;
}

.ButtonRoot {
  flex: 1 1 auto;
  border-right-color: color-mix(
    in srgb,
    var(--mb-color-bg-white),
    transparent 25%
  );

  &[data-variant="filled"]:hover {
    border-right-color: color-mix(
      in srgb,
      var(--mb-color-bg-white),
      transparent 25%
    );
  }
}
