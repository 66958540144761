.CloseButton {
  color: var(--mb-color-text-dark);
  text-decoration: none;

  &:hover {
    color: var(--mb-color-brand);
  }
}

.HeaderTitleContainer {
  flex: 1;
  font-size: 1.17em;
  font-weight: bold;
}

.backButton {
  cursor: pointer;

  &:hover {
    color: var(--mb-color-brand);
  }
}

.defaultBackButton {
  cursor: pointer;
  color: var(--mb-color-text-medium);
  font-size: 0.83em;
  text-transform: uppercase;
  letter-spacing: 0.06em;

  &:hover {
    color: var(--mb-color-brand);
  }
}

.icon {
  flex: 0 0 auto;
}

.title {
  word-break: break-word;
}
