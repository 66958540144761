.formula {
  composes: bordered rounded from "style";
  background-color: var(--mb-color-bg-light);
  cursor: pointer;
}

.formulaHeader {
  composes: textBrand py1 px2 from "style";
  align-items: center;
  display: flex;
}

.formulaTitle {
  composes: ml2 from "style";
  font-size: 16px;
}

.formulaDefinitionInner {
  composes: p2 from "style";
}

.formulaDefinition {
  overflow: hidden;
}
