.root {
  display: flex;
  padding: 0 0.5rem;
  flex-shrink: 0;
  font-weight: 600;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--mb-color-border);
}

.rowsCount {
  padding-right: 0.5rem;
}
