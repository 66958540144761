.textInput {
  border-radius: 0.5rem;
}

.modalContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.modalBody {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.singlePickerView {
  border-top: 1px solid var(--mb-color-border);
  flex-grow: 1;
  height: 0;
}
