.wrapper {
  position: relative;
  border-bottom: 1px solid var(--mb-color-border);
  display: flex;
  flex-direction: column;

  &.formatting {
    background-color: var(--mb-color-bg-medium);
  }
}

.error {
  color: var(--mb-color-error);
  margin: 0.5rem 0;
  white-space: pre-wrap;
}

.toolbar {
  position: absolute;
  top: 0;
  right: 0;
}

.toolbarButton {
  aspect-ratio: 1;
}

.editor {
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  color: var(--mb-color-text-dark);
  margin-right: calc(28px + var(--mantine-spacing-sm));

  :global {
    .cm-editor {
      background: none;
      height: 100%;
      white-space: pre;
    }

    .cm-focused {
      outline: none;
    }

    .cm-scroller {
      background-color: none;
    }

    .cm-content {
      color: var(--mb-color-text-dark);
      padding-top: 10px;
      background: none;
    }

    .cm-line {
      background: none;
    }

    .cm-gutters {
      border: none;
    }

    .cm-gutter {
      background-color: var(--mb-color-bg-light);
    }

    .cm-gutterElement {
      font-weight: bold;
      font-size: 10px;
      color: var(--mb-color-text-light);
      vertical-align: baseline;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span[title="Fold line"] {
        position: relative;
        top: -3px;
      }
    }

    .cm-activeLineGutter {
      background: none;
    }

    .cm-selectionBackground {
      background: var(--mb-color-focus) !important;
      border-radius: 1px;
    }

    .cm-selectionMatch {
      background: none;
    }

    .cm-cursor {
      border-left-width: 1.6px;
    }

    .cm-tooltip {
      border: none !important;
      background: none !important;
    }

    .cm-tooltip-autocomplete {
      display: none;
    }

    .cm-snippetField {
      font-style: italic;
      background: none;
    }

    .cm-lintPoint {
      display: none;
    }
  }
}

.tooltips {
  :global {
    .cm-tooltip {
      border: none !important;
      background: none !important;
    }

    .cm-tooltip-autocomplete {
      display: none;
    }
  }
}

.shortcuts {
  position: absolute;
  bottom: var(--mantine-spacing-sm);
  left: calc(1.6rem + var(--mantine-spacing-sm));
  right: 0;
  transition: opacity 300ms;
}
