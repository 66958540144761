.DashboardCardRootDragging {
  .DashCardActionsPanel {
    display: none;
  }
}

.DashboardCardRoot {
  &:hover,
  &:focus-within {
    .DashCardActionsPanel {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.DashCardRoot {
  background-color: var(--mb-color-bg-dashboard-card);
  scroll-margin: 6px 0;

  &.isNightMode {
    border-color: var(--mb-color-bg-night);
    background-color: var(--mb-color-bg-night);
  }

  &.isUsuallySlow {
    border-color: var(--slow-card-border-color);
  }

  &.hasHiddenBackground {
    border: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
  }

  &.shouldForceHiddenBackground {
    background: var(--mb-color-bg-light);
    box-shadow: none !important;

    &.isNightMode {
      background: var(--mb-color-bg-black);
    }

    &.isEmbeddingSdk {
      background: var(--mb-color-bg-dashboard);
    }
  }
}
