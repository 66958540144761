/**
 * The upsell color palette is designed to function in harmony with the original Metabase set of Blues, Grays and Purples
 * but with a twist. All three colors are new and should not be used elsewhere in the product experience.
 */
:root {
  --mb-color-upsell-primary: #005999;
  --mb-color-upsell-secondary: #bff4ff;
  --mb-color-upsell-gem: #00d4ff;
}

.UpsellGem {
  color: var(--mb-color-upsell-gem);
  flex-shrink: 0;
}

.UpsellCTALink {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  font-weight: bold;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 2rem;
  color: var(--mb-color-upsell-primary);
  background-color: var(--mb-color-upsell-secondary);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: var(--mb-color-upsell-primary);
    color: var(--mb-color-text-white);
  }
}

.UpsellBannerComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  overflow: hidden;
  border: 1px solid var(--mb-color-border);
  border-radius: 0.5rem;

  .UpsellCTALink {
    flex-shrink: 0;
  }
}

.SecondaryCTALink {
  display: inline;
  color: var(--mb-color-upsell-primary);
  font-weight: 700;
  margin-left: 0.25rem;
}

.UpsellBigCardComponent {
  border: 1px solid var(--mb-color-border);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: fit-content;
  overflow: hidden;

  img {
    aspect-ratio: 4/3;
    max-height: 21.875rem;
  }

  .UpsellCTALink {
    font-size: 0.875rem;
    padding: 0.75rem 1rem;
  }
}

.UpsellCardComponent {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--mb-color-upsell-secondary);
  background-color: var(--mb-color-bg-white);

  &.Large {
    .MainStack {
      padding-top: 2rem;
      padding-bottom: 3rem;
    }

    .UpsellCTALink {
      margin: 0;
      margin-left: 3rem;
      width: 10rem;
    }
  }

  &.Default {
    .Title {
      font-size: 0.875rem;
    }
  }
}

.UpsellPillComponent {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  font-weight: bold;
  font-size: 0.75rem;
  text-decoration: none;
  padding: 0.25rem 0.75rem;
  border-radius: 2rem;
  border: 1px solid var(--mb-color-upsell-secondary);
  color: var(--mb-color-upsell-primary);
  cursor: pointer;

  &:hover {
    background-color: var(--mb-color-upsell-primary);
    color: var(--mb-color-text-white);
    border: 1px solid var(--mb-color-upsell-primary);
  }
}
