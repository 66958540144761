.Schedule {
  line-height: 2.5rem;
  display: grid;
  grid-template-columns: min-content auto;
  gap: var(--schedule-grid-gap, 0.5rem);
  row-gap: var(--schedule-row-gap, 0.35rem);
  align-items: start;
  font-weight: var(--schedule-font-weight, normal);
}

.ControlGroup {
  display: flex;
  gap: 0.25rem;
  flex-flow: row nowrap;
  flex-shrink: 0;
  grid-column: 2;
  align-items: center;
}

.TextInFirstColumn {
  display: flex;
  flex-flow: row wrap;
  white-space: nowrap;
  align-items: flex-start;
  font-weight: var(--schedule-first-column-font-weight, normal);
}

.TextInSecondColumn {
  line-height: 1.5rem;
  grid-column: 2;
  font-weight: normal;
}

.CompactLabels .TextInFirstColumn {
  justify-content: flex-end;
}
