.root {
  color: var(--mb-color-text-dark);
}

.title {
  font-size: var(--mantine-font-size-xl);
  font-weight: var(--mantine-heading-font-weight);
}

.overlay {
  background-color: color-mix(
    in hsl,
    var(--mb-color-bg-black),
    transparent 40%
  );
  padding: 11px;
}

.content {
  background-color: var(--mb-color-background);
}

.header {
  background-color: var(--mb-color-background);
}

.ModalCloseButton {
  color: var(--mb-color-text-tertiary) !important;
  padding: 0;
  width: rem(18px);
  height: rem(18px);

  &:hover {
    color: var(--mb-color-text-secondary) !important;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
