.highlight {
  font-family: var(--mb-default-monospace-font-family);
  font-size: 0.85em;
  overflow: auto;

  &.block {
    padding: var(--mantine-spacing-sm);
    background-color: var(--mb-color-bg-medium);
    color: var(--mb-color-text-primary);
    border-radius: var(--mantine-radius-sm);
    white-space: pre;
    display: block;
  }

  &.inline {
    display: inline;
    background-color: none;
  }
}
