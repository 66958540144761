.DashboardGridContainer {
  &.isFixedWidth {
    margin: 0 auto;
    max-width: var(--dashboard-fixed-width);
  }
}

.DashboardCardContainer {
  position: relative;
  z-index: 1;
  container-name: DashboardCard;
  container-type: inline-size;

  /**
  * Dashcards are positioned absolutely so each one forms a new stacking context.
  * The dashcard user is currently interacting with needs to be positioned above other dashcards
  * to make sure it's not covered by absolutely positioned children of neighboring dashcards.
  *
  * @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_positioned_layout/Understanding_z-index/Stacking_context
  */
  &:hover,
  &:focus-within {
    z-index: 2;
  }

  &.isAnimationDisabled {
    transition: none;
  }

  @media (prefers-reduced-motion) {
    /* short duration (instead of none) to still trigger transition events */
    transition-duration: 10ms !important;
  }

  /* Google Maps widgets */
  :global(.gm-style-mtc),
  :global(.gm-bundled-control),
  :global(.PinMapUpdateButton),
  :global(.leaflet-container) :global(.leaflet-control-container) {
    opacity: 0.01;
    transition: opacity 0.3s linear;
  }

  &:hover :global(.gm-style-mtc),
  &:hover :global(.gm-bundled-control),
  &:hover :global(.PinMapUpdateButton),
  :global(.leaflet-container):hover :global(.leaflet-control-container) {
    opacity: 1;
  }

  .Card {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
  }
}
